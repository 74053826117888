export const fetchPOST = (url: string, body: {}) => {
  const fullURL = process.env.REACT_APP_BASE_URL + url;
  const bodyJSON = JSON.stringify(body);
  const token = localStorage.getItem('token');
  return new Promise((resolve, reject) => {
    fetch(fullURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token ? token : '',
      },
      body: bodyJSON,
    })
      .then(async (response) => {
        const data = await response.json();
        resolve({ ok: response.ok, status: response.status, data });
      })
      .catch((error) => reject(error));
  });
};

export const fetchGET = (url: string) => {
  const fullURL = process.env.REACT_APP_BASE_URL + url;
  const token = localStorage.getItem('token');
  return new Promise((resolve, reject) => {
    fetch(fullURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token ? token : '',
      },
    })
      .then(async (response) => {
        const data = await response.json();
        resolve({ ok: response.ok, status: response.status, data });
      })
      .catch((error) => reject(error));
  });
};
