import { useState, useEffect } from 'react';
import { Tournament } from '../Types';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import Card from './Card';
import { fetchGET } from '../utils/dataUtils';
import { TOURNAMENTS_ALL } from '../urls';

const Tournaments = () => {
  const [tournaments, setTournaments] = useState<Tournament[]>([
    {
      name: `Men's 3.0`,
      category:
        'Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum ',
      start_date: 'Sep 23, 2023',
      end_date: 'Sep 24, 2023',
      active: true,
      club_id: '1',
    },
    {
      name: `Men's 3.5`,
      category:
        'Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum ',
      start_date: '',
      end_date: '',
      active: true,
      club_id: '1',
    },
    {
      name: `Women's 3.0`,
      category:
        'Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum ',
      start_date: '',
      end_date: '',
      active: true,
      club_id: '1',
    },
    {
      name: `Women's 3.5`,
      category:
        'Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum ',
      start_date: '',
      end_date: '',
      active: true,
      club_id: '1',
    },
    {
      name: `Kids `,
      category:
        'Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum ',
      start_date: '',
      end_date: '',
      active: true,
      club_id: '1',
    },
    {
      name: `Seniors `,
      category:
        'Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum Lorum ipsum ',
      start_date: '',
      end_date: '',
      active: true,
      club_id: '1',
    },
  ]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMesssage] = useState('' as string);

  useEffect(() => {
    fetchGET(TOURNAMENTS_ALL)
      .then(({ ok, status, data }: any) => {
        setLoading(false);
        if (!ok) {
          setError(true);
          setErrorMesssage(data.message);
          return;
        }
        setError(false);
        setErrorMesssage('');
        setTournaments(data);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setErrorMesssage(
          "We're having trouble loading tournaments. Please try again later."
        );
      });
  }, []);
  return (
    <Flex
      justifyContent={'space-evenly'}
      flexDirection={{ base: 'column', xl: 'row' }}
      flexWrap={'wrap'}
    >
      {loading && (
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
      )}
      {!loading && error && <Text color='red.400'>{errorMessage}</Text>}
      {!loading &&
        !error &&
        tournaments.map((tournament) => {
          return (
            <Card
              name={tournament.name}
              description={tournament.category}
              start_date={tournament.start_date}
            ></Card>
          );
        })}
    </Flex>
  );
};
export default Tournaments;
