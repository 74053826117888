'use client';

import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  Stack,
  Button,
  Link,
  Badge,
  useColorModeValue,
} from '@chakra-ui/react';
import { DEFAULT_BUTTON_BACKGROUND, DEFAULT_BUTTON_HOVER } from '../constants';
import { useNavigate } from 'react-router-dom';

export default function Card({
  name,
  description,
  start_date,
}: {
  name: string;
  description: string;
  start_date: string;
}) {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/register');
  };
  return (
    <Center py={6}>
      <Box
        minW={{ base: '280px', md: '320px', lg: '350px' }}
        minH={{ base: '200px', md: '300px' }}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        boxShadow={'2xl'}
        rounded={'lg'}
        p={6}
        textAlign={'center'}
        alignContent={'center'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-evenly'}
        alignItems={'initial'}
      >
        {/* <Avatar
          size={'xl'}
          src={
            'https://images.unsplash.com/photo-1520810627419-35e362c5dc07?ixlib=rb-1.2.1&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&ixid=eyJhcHBfaWQiOjE3Nzg0fQ'
          }
          mb={4}
          pos={'relative'}
          _after={{
            content: '""',
            w: 4,
            h: 4,
            bg: 'green.300',
            border: '2px solid white',
            rounded: 'full',
            pos: 'absolute',
            bottom: 0,
            right: 3,
          }}
        /> */}
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          {name}
        </Heading>
        <Text fontWeight={600} color={'gray.500'} mb={4}>
          {start_date}
        </Text>
        <Text
          textAlign={'center'}
          color={useColorModeValue('gray.700', 'gray.400')}
          px={3}
        >
          {description}
        </Text>

        {/* <Stack align={'center'} justify={'center'} direction={'row'} mt={6}>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue('gray.50', 'gray.800')}
            fontWeight={'400'}
          >
            #art
          </Badge>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue('gray.50', 'gray.800')}
            fontWeight={'400'}
          >
            #photography
          </Badge>
          <Badge
            px={2}
            py={1}
            bg={useColorModeValue('gray.50', 'gray.800')}
            fontWeight={'400'}
          >
            #music
          </Badge>
        </Stack> */}

        <Stack mt={8} direction={'row'} spacing={4} justifyContent={'center'}>
          {/* <Link
            flex={1}
            fontSize={'sm'}
            rounded={'full'}
            _focus={{
              bg: 'gray.200',
            }}
          >
            Edit Registration
          </Link> */}
          <Button
            flex={1}
            fontFamily={'heading'}
            fontSize={'md'}
            // rounded={'full'}
            bgGradient={DEFAULT_BUTTON_BACKGROUND}
            _hover={{
              bgGradient: { DEFAULT_BUTTON_HOVER },
              boxShadow: 'xl',
            }}
            color={'white'}
            // boxShadow={
            //   '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
            // }
            maxWidth={'200px'}
            height={'50px'}
            onClick={handleRegister}
          >
            Register
          </Button>
        </Stack>
      </Box>
    </Center>
  );
}
