import { useState, useEffect, ReactElement } from 'react';
import AppContext from './AppContext';

const AppProvider = ({ children }: { children: ReactElement }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [token, setToken] = useState('');

  return (
    <AppContext.Provider
      value={{ isUserLoggedIn, token, setIsUserLoggedIn, setToken }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
