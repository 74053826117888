import {
  Flex,
  VStack,
  Stack,
  Heading,
  Text,
  Box,
  useColorModeValue,
  Button,
  FormControl,
  InputGroup,
  Input,
  FormLabel,
} from '@chakra-ui/react';
import { DEFAULT_BUTTON_BACKGROUND, DEFAULT_BUTTON_HOVER } from '../constants';
import { useCallback, useState } from 'react';
import { fetchPOST } from '../utils/dataUtils';
import { VERIFY_URL } from '../urls';
import { useLocation } from 'react-router-dom';

const Verify = () => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { search } = useLocation();

  const handleOtpChange = useCallback((e: any) => {
    setOtp(e.target.value);
  }, []);

  const handleSubmitClick = useCallback(() => {
    const queryParams = new URLSearchParams(search);
    setError(false);
    setErrorMessage('');
    const body = {
      email: queryParams.get('email'),
      verificationCode: otp,
    };
    setLoading(true);
    fetchPOST(VERIFY_URL, body)
      .then(({ ok, status, data }: any) => {
        setLoading(false);
        if (!ok) {
          setError(true);
          setErrorMessage(data.message);
          return;
        }
        alert('Successfully verified!');
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
        setErrorMessage('Something went wrong. Please try again later.');
      });
  }, [otp, search]);

  return (
    <Flex w='100vw' justifyContent='center'>
      <Flex
        w={{ base: '100%', md: '75%' }}
        backgroundColor='gray.50'
        mb='10'
        mt='10'
        rounded='xl'
        justifyContent='center'
      >
        <VStack w='100%'>
          <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6} w='100%'>
            <Stack align={'center'}>
              <Heading fontSize={'2xl'} textAlign={'center'}>
                Email verification
              </Heading>
            </Stack>
            <Box
              rounded={'lg'}
              bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
            >
              <Stack spacing={6}>
                <FormControl id='password' isRequired mt='2'>
                  <FormLabel>OTP</FormLabel>
                  <InputGroup>
                    <Input
                      type={'text'}
                      value={otp}
                      onChange={handleOtpChange}
                    />
                  </InputGroup>
                </FormControl>
                <Stack spacing={10} pt={2}>
                  <Button
                    loadingText='Submitting'
                    size='lg'
                    bgGradient={DEFAULT_BUTTON_BACKGROUND}
                    color={'white'}
                    _hover={{
                      bg: DEFAULT_BUTTON_HOVER,
                    }}
                    isDisabled={!otp}
                    onClick={handleSubmitClick}
                    isLoading={loading}
                  >
                    Submit
                  </Button>
                  {error && <Text color={'red.500'}>{errorMessage}</Text>}
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </VStack>
      </Flex>
    </Flex>
  );
};

export default Verify;
