import { AUTHENTICATE_URL } from '../urls';

export const authenticate = async () => {
  let promise = new Promise((resolve, reject) => {
    if (!localStorage.getItem('token')) {
      reject('No token found');
      return;
    }
    const url = process.env.REACT_APP_BASE_URL + AUTHENTICATE_URL;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: localStorage.getItem('token') || '',
      },
    })
      .then(async (res) => {
        const response = await res.json();
        if (res.ok) {
          localStorage.setItem('token', response.jwtToken);
          resolve({
            token: localStorage.getItem('token'),
          });
        } else {
          reject('Invalid login');
        }
      })
      .catch((err) => {
        reject('Error authenticating');
      });
  });

  return promise;
};
