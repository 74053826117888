import React from 'react';

type AppContextType = {
  isUserLoggedIn: boolean;
  token: string;
  setIsUserLoggedIn: (isUserLoggedIn: boolean) => void;
  setToken: (token: string) => void;
};

const AppContext = React.createContext<AppContextType>({
  isUserLoggedIn: false,
  token: '',
  setIsUserLoggedIn: () => {},
  setToken: () => {},
});

export default AppContext;
