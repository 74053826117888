'use client';

import { useState } from 'react';
import {
  Progress,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormHelperText,
  InputRightElement,
  Radio,
} from '@chakra-ui/react';

import { useToast } from '@chakra-ui/react';
import { DEFAULT_BUTTON_BACKGROUND, DEFAULT_BUTTON_HOVER } from '../constants';
import { useNavigate } from 'react-router-dom';

export default function Registration() {
  const toast = useToast();
  const [step, setStep] = useState(3);
  const [progress, setProgress] = useState(100);
  const [partner, setPartner] = useState('');
  const [partnerRegistered, setPartnerRegistered] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');

  const handlePartnerChange = (e: any) => {
    setPartner(e);
  };

  const handlePartnerRegisteredChange = (e: any) => {
    setPartnerRegistered(e);
  };

  const Form3 = () => {
    return (
      <>
        {/* <Heading w='100%' textAlign={'center'} fontWeight='normal'>
        Social Handles
      </Heading> */}
        <SimpleGrid columns={1} spacing={6}>
          <FormControl id='partner' isRequired mt='2'>
            <FormLabel>Partner</FormLabel>
            <Flex alignItems='center'>
              <Radio
                value='1'
                onChange={() => handlePartnerChange('1')}
                isChecked={partner === '1'}
              >
                I have a partner
              </Radio>
              <Radio
                value='0'
                ml={5}
                onChange={() => handlePartnerChange('0')}
                isChecked={partner === '0'}
              >
                I need a partner
              </Radio>
            </Flex>
          </FormControl>
          <FormControl
            id='partner'
            isRequired
            mt='2'
            isDisabled={partner !== '1'}
          >
            <FormLabel>Has your partner already registered</FormLabel>
            <Flex alignItems='center'>
              <Radio
                value='1'
                onChange={() => handlePartnerRegisteredChange('1')}
                isChecked={partnerRegistered === '1'}
              >
                Yes
              </Radio>
              <Radio
                value='0'
                ml={5}
                onChange={() => handlePartnerRegisteredChange('0')}
                isChecked={partnerRegistered === '0'}
              >
                No
              </Radio>
            </Flex>
          </FormControl>

          <FormControl
            id='partner'
            mt={1}
            isDisabled={partner !== '1' || partnerRegistered !== '1'}
          >
            <Select
              id='country'
              name='country'
              autoComplete='country'
              placeholder='Select partner'
              focusBorderColor='brand.400'
              shadow='sm'
              size='sm'
              w='full'
              rounded='md'
            >
              <option>United States</option>
              <option>Canada</option>
              <option>Mexico</option>
            </Select>
          </FormControl>
        </SimpleGrid>
      </>
    );
  };

  const navigate = useNavigate();

  return (
    <>
      <Box
        borderWidth='1px'
        rounded='lg'
        shadow='1px 1px 3px rgba(0,0,0,0.3)'
        maxWidth={800}
        p={6}
        m='10px auto'
        as='form'
        bg={'white'}
      >
        {<Form3 />}
        <ButtonGroup mt='5%' w='100%'>
          <Flex w='100%' justifyContent='space-between'>
            <Flex>
              <Button
                onClick={() => {
                  navigate('/');
                }}
                isDisabled={step === 1}
                colorScheme='teal'
                variant='solid'
                w='7rem'
                mr='5%'
              >
                Back
              </Button>
            </Flex>
            {step === 3 ? (
              <Button
                w='7rem'
                bgGradient={DEFAULT_BUTTON_BACKGROUND}
                color={'white'}
                _hover={{
                  bgGradient: { DEFAULT_BUTTON_HOVER },
                  boxShadow: 'xl',
                }}
                variant='solid'
                onClick={() => {
                  toast({
                    title: 'Account created.',
                    description: "We've created your account for you.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  });
                }}
              >
                Submit
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
      </Box>
    </>
  );
}
