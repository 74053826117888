import { ReactElement, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AppContext from '../context/AppContext';
import { authenticate } from './authenticate';
import { Spinner } from '@chakra-ui/react';
import NavBar from '../NavBar/NavBar';
import Sidebar from '../Sidebar/Sidebar';

const PrivateRoute = ({ children }: { children: ReactElement }) => {
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  const appContext = useContext(AppContext);
  const { setIsUserLoggedIn, setToken } = appContext;

  useEffect(() => {
    setLoading(true);
    authenticate()
      .then((res: any) => {
        setLoading(false);
        if (res.token) {
          setIsUserLoggedIn(true);
          setToken(res.token);
          setVerified(true);
        } else {
          setIsUserLoggedIn(false);
          setToken('');
          setVerified(false);
        }
      })
      .catch((err) => {
        setIsUserLoggedIn(false);
        setToken('');
        setVerified(false);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    );
  }
  console.log('Pr');

  if (!loading && verified) {
    return (
      <>
        <Sidebar>{children}</Sidebar>
      </>
    );
  }

  return <Navigate to='/login' />;
};

export default PrivateRoute;
