import React from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import Footer from './Footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import PrivateRoute from './utils/PrivateRoute';
import AppProvider from './context/AppProvider';
import Tournaments from './Tournaments/Tournaments';
import Verify from './Verify/Verify';
import Registration from './Registration/Registration';

const Landing = React.lazy(() => import('./Landing/Landing'));
const DisclaimerPage = React.lazy(() => import('./Disclaimer/DisclaimerPage'));
const SignUp = React.lazy(() => import('./Signup/Signup'));

function App() {
  return (
    <ChakraProvider>
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path='/login'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Landing />
                </React.Suspense>
              }
            />
            <Route
              path='/disclaimer'
              element={
                <React.Suspense fallback={<>...</>}>
                  <DisclaimerPage />
                </React.Suspense>
              }
            />
            <Route
              path='/signup'
              element={
                <React.Suspense fallback={<>...</>}>
                  <SignUp />
                </React.Suspense>
              }
            />
            <Route
              path='/verify'
              element={
                <React.Suspense fallback={<>...</>}>
                  <Verify />
                </React.Suspense>
              }
            />
            <Route
              path='/'
              element={
                <PrivateRoute>
                  <Tournaments />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path='/register'
              element={
                <PrivateRoute>
                  <Registration />
                </PrivateRoute>
              }
            ></Route>
          </Routes>
          {/* <Footer /> */}
        </BrowserRouter>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
